<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <QRReader :camera="camera" @query="onQuery"></QRReader>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-1">
                <v-layout justify-center>
                    <v-flex xs12 sm10 md8 lg8>
                        <door-selector
                            :enable="stepNo === 2"
                            :box-id="boxId"
                            :door-id.sync="doorId"
                            @select="selectDoorByStaff"
                            @back="preStep"
                        ></door-selector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-chip label color="green" text-color="white" class="title">
                            儲格&nbsp;&nbsp;<b>{{doorId}}</b>
                        </v-chip>
                        <v-form class="mt-2" ref="form" v-model="receiver.valid">
                            <v-text-field outline clearable autofocus prepend-icon="smartphone" name="phone" label="收件手機號碼" type="tel"
                                          v-model="receiver.phone" mask="##########" :rules="phoneRules" data-cy="phoneField" required>
                            </v-text-field>
                            <v-text-field outline clearable prepend-icon="local_shipping" name="password" label="包裹來源"
                                          type="text" required v-model="receiver.note" data-cy="noteField">
                            </v-text-field>
                        </v-form>
                        <v-layout justify-center>
                            <v-btn flat large block color="primary" @click="setStepNo(2)">
                                <v-icon>arrow_back_ios</v-icon>上一步
                            </v-btn>
                            <v-btn large block color="primary" :disabled="!receiver.valid" @click="controlDoor" data-cy="controlDoorBtn">使用儲格</v-btn>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-list subheader>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">儲格編號&nbsp;&nbsp;<b>{{doorId}}</b></v-list-tile-title>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <v-btn dark depressed color="cyan" class="subheading" @click="reopen" data-cy="reopenBtn">關門</v-btn>
                                </v-list-tile-action>
                            </v-list-tile>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">收件手機&nbsp;&nbsp;<b>{{receiver.phone}}</b></v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">包裹來源&nbsp;&nbsp;<b>{{receiver.note}}</b></v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                        <v-alert :value="true" type="warning" class="subheading">{{ $t('putIn.close-door') }}</v-alert>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn large outline class="body-2" color="success" @click="conti" data-cy="continueBtn">繼續</v-btn>
                    <v-btn large block class="ma-1" color="primary" @click="finish" data-cy="finishBtn">完成</v-btn>
                </v-layout>
            </v-stepper-content>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import PutinMixin from '@/mixins/PutinMixin'
import { HostUrl } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'

export default {
    name: 'StaffPutin',
    mixins: [PutinMixin, BoxIdWatcherMixin, ControlResultWatcher],
    data() {
        return {
            titles: ['掃描 QR Code', '選擇儲格', '收件者資料', '放入包裹'],
            receiver: {
                valid: false,
                phone: '',
                note: ''
            },
            phoneRules: [
                v => !!v || '請輸入手機號碼',
                v => /^09\d{8}$/.test(v) || '請輸入有效手機號碼'
            ]
        }
    },
    methods: {
        initReceiver() {
            this.receiver = {
                valid: false,
                phone: '',
                note: ''
            }
        },
        init() {
            store.commit('initTransaction')
            store.commit('setTitle', '掃描 QR Code')
            store.commit('setTargetBoxId', '')
            this.boxId = ''
            this.preStepNo = 0
            this.stepNo = 1
            this.trackNo = null
            this.doorId = 0
            this.doorInfo = null
            this.camera = 'auto'
            this.initReceiver()
        },
        selectDoorByStaff(door, rate, isAvailable) {
            this.selectDoor(door, rate)
            if(isAvailable) this.nextStep()
        },
        async controlDoor() {
            this.showDialog('控制儲格', '控制失敗')
            try {
                let response = await axios.post(HostUrl + '/api/staff/putin', {
                    box_id: store.getters.targetBoxId,
                    door_id: this.doorId,
                    phone: this.receiver.phone,
                    note: this.receiver.note,
                    client_id: store.getters.clientId
                }, getAxiosConfig());
                if (response.data.code == 0) {
                    this.trackNo = response.data.track_no;
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    });
                }
            } catch (error) {
                store.commit('setSnackBar', {
                    message: '控制失敗，請稍後再試',
                    color: 'error'
                });
            }
        },
        async conti() {
            this.showDialog(i18n.t('putIn.upload-data'), i18n.t('putIn.upload-data-fail'))
            try {
                let response = await axios.post(HostUrl + '/api/staff/putin/continue', {
                    track_no: this.trackNo
                }, getAxiosConfig())
                if (response.data.code == 0) {
                    this.closeDialog()
                    this.showDialog(i18n.t('putIn.loading-data'), i18n.t('putIn.loading-data-fail'))
                    setTimeout(() => {
                        this.doorId = 0
                        this.doorInfo = null
                        this.setStepNo(2)
                        this.closeDialog()
                    }, 5000)
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    })
                    this.closeDialog()
                }
            } catch (error) {
                store.commit('setSnackBar', {
                    message: i18n.t('putIn.upload-data-fail-message'),
                    color: 'error'
                })
                this.closeDialog()
            }
        },
        async finish() {
            this.showDialog(i18n.t('putIn.upload-data'), i18n.t('putIn.upload-data-fail'))
            try {
                let response = await axios.post(HostUrl + '/api/staff/putin/finish', {
                    box_id: store.getters.targetBoxId
                }, getAxiosConfig());
                if (response.data.code == 0) {
                    this.initReceiver();
                    this.$router.push(`/${i18n.locale}/staff/menu`);
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    });
                }
            } catch (error) {
                store.commit('setSnackBar', {
                    message: i18n.t('putIn.upload-data-fail-message'),
                    color: 'error'
                });
            } finally {
                this.closeDialog()
            }
        }
    }
};
</script>

<style scoped>
</style>
